import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import NavbarBottom from '../../../components/NavbarBottom/NavbarBottom';
import ProjectView from '../../../components/ProjectView/ProjectView';
import photo1 from '../../../assets/projects/project13/1.jpg';

class Project13 extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        photos: [
          { id: 1, data: photo1 }
        ]
      }
    }
  
    render() {
      const { photos} = this.state;
      return (
        <div>
          <Navbar title="Vinodh Residence, Salem" />
              <ProjectView photos={photos}/>
          <NavbarBottom />
        </div>
      )
    }
  }
  
  export default Project13;