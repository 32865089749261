import React from 'react';
import './ProjectView.css';
import Loading from '../../components/Loading/Loading';
// import arrow from '../../assets/projectarrow.svg';


import './3dstyle.scss';


const slides = {};

const pic = (props) =>{
  Object.assign(slides , props)
}

function useTilt(active) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current || !active) {
      return;
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined
    };

    let el = ref.current;

    const handleMouseMove = (e) => {
      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = e.clientX;
      state.mouseY = e.clientY;
      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px);
      el.style.setProperty("--py", py);
    };

    el.addEventListener("mousemove", handleMouseMove);

    return () => {
      el.removeEventListener("mousemove", handleMouseMove);
    };
  }, [active]);

  return ref;
}

const initialState = {
  slideIndex: 0
};

const slidesReducer = (state, event) => {
  if (event.type === "NEXT") {
    console.log("NEXT");
    console.log(state.slideIndex);
    return {
      ...state,
      slideIndex:(state.slideIndex + 1) % slides.photo.length,
    };
  }
  if (event.type === "PREV") {
    console.log("PREV");
    console.log(state.slideIndex);
    return {
      ...state,
      slideIndex: state.slideIndex === 0 ? slides.photo.length - 1 : state.slideIndex - 1
        
    };
  }
};

function Slide({ slide, offset }) {
  const active = offset === 0 ? true : null;
  const ref = useTilt(active);

  return (
    <div
      ref={ref}
      className="slide"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1.4 : -1.4
      }}
    >
      <div
        className="slideContent"
        style={{ backgroundImage: `url(${slide})` }}
      >
      </div>
    </div>
  );
}

function App(props) {
  const [state, dispatch] = React.useReducer(slidesReducer, initialState);
  pic(props)
  return (
      <div className = "SliderMainProperties">
    <div className="slides">
      <button onClick={() => dispatch({ type: "PREV" })}>‹</button>

      {[...slides.photo, ...slides.photo, ...slides.photo].map((slide, i) => {
        let offset = slides.photo.length + (state.slideIndex - i);
        return <Slide slide={slide.data} offset={-offset} key={i} />;
      })}
      <button onClick={() => dispatch({ type: "NEXT" })}>›</button>
    </div>

    </div>
  );
}

class ProjectView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      view: 1,
      mobile: false,
      loading: true
    }
  }

  componentDidMount() {
    if (window.innerWidth <= 769) {
      this.setState({ mobile: true });
    }
    setTimeout(() => {
      this.setState({ loading: false })
    }, 3000)
  }

  render() {
    const { photos } = this.props;

    const { mobile, loading } = this.state;   

    return (
      <div>
        {loading === false ?
          <div>
            {mobile ?
              <div className="container project project_view">
                {photos.map(({ id, data }) => (
                  <img className="project1_img mb-5" key={id} src={data} alt="photo1" onClick={() => this.setState({ view: id })} />
                ))}
              </div>
              :
              <div>
                <App photo = {photos} />
              </div>
            }
          </div>
          :
          <Loading />
        }
      </div>
    )
  }
}

export default ProjectView;