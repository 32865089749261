import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import NavbarBottom from '../../../components/NavbarBottom/NavbarBottom';
import ProjectView from '../../../components/ProjectView/ProjectView';
import photo1 from '../../../assets/projects/project22/1.jpg';
import photo2 from '../../../assets/projects/project22/2.jpg';
import photo3 from '../../../assets/projects/project22/3.jpg';
import photo4 from '../../../assets/projects/project22/4.jpg';

class Project22 extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        photos: [
          { id: 1, data: photo1 },
          { id: 2, data: photo2 },
          { id: 3, data: photo3 },
          { id: 4, data: photo4 },
        ]
      }
    }
  
    render() {
      const { photos} = this.state;
      return (
        <div>
          <Navbar title="Phifer, Chennai" />
              <ProjectView photos={photos}/>
          <NavbarBottom />
        </div>
      )
    }
  }
  
  export default Project22;