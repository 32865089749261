import React from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';
import Navbar from '../../components/Navbar/Navbar';
import NavbarBottom from '../../components/NavbarBottom/NavbarBottom';
import Loading from '../../components/Loading/Loading';
import Location from '../../assets/Contact/location.svg';


// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import ImageSlider from "react-image-comparison-slider";
// import photo1 from '../../assets/projects/project1/1.jpg';
// import photo2 from '../../assets/projects/project1/2.jpg';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      popup: true,
      loading: true,
      class: "d-none overlay"
    }
    this.changeHandle = this.changeHandle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeHandle(event) {
    this.setState({ [event.target.name]: event.target.value });
    event.preventDefault();
  }

  async handleSubmit(event) {
    event.preventDefault();

    var templateParams = {
      name: this.state.name,
      number: this.state.phone,
      email: this.state.email,
      writeamessage: this.state.message
    };

    var status;

    await emailjs.send('service_t7sfknn', 'template_wo203v2', templateParams, 'user_6O39mGHzcpnft8fsY78kQ')
      .then(function (response) {
        status = "success";
        console.log('SUCCESS!', response.status, response.text);
      }, function (error) {
        console.log('FAILED...', error);
      });
    console.log(status);
    if (status === "success") {
      this.setState({ class: "d-block overlay" });
      this.setState({ name: "" })
      this.setState({ email: "" })
      this.setState({ phone: "" })
      this.setState({ message: "" })
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 1800)
  }

  render() {
    return (
      <div>
        {this.state.loading === false ?
          <div>
            <Navbar title="Contact" />
            <div className="container contact">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row contact_info">
                    <div className="col-12 mb-5">
                      <img src = {Location} className = "addressicon mr-2 mb-4" alt = "location" ></img>
                      <p className="row mt-3"> 
                        <strong className="Contact-head col-12 col-md-4">Chennai : </strong>
                        <span className="Contact-details col-12 col-md-8">AK-2, RBN Tower,  2nd Floor, 4th Avenue, Shanthi Colony, Anna Nagar, Chennai - 600040</span>
                      </p>
                      <p className="row mt-3"> 
                        <strong className="Contact-head col-12 col-md-4">Salem : </strong>
                        <span className="Contact-details col-12 col-md-8">#49/A, Ramakrishna Rd, Salem,Tamil Nadu 636007</span>
                      </p>
                      <p className="row mt-5"> 
                        <strong className="Contact-head col-12 col-md-4">Coimbatore : </strong>
                        <span className="Contact-details col-12 col-md-8">Anna Street, First Floor, Venkitapuram, Coimbatore-641025</span>
                      </p>
                    </div>
                    <div className="col-12 mb-4">
                      <p className="Contact-details"><i class="far fa-envelope emailicon mr-3"></i>
                      <div className="ml-4">
                      <div className="mt-2"><strong className="Contact-head">Info : <a href="mailto:info@evokearch.in">info@evokearch.in</a></strong><br/></div>
                      <div className="mt-2"><strong className="Contact-head">Career : <a href="mailto:work@evokearch.in">work@evokearch.in</a></strong></div>
                      </div>
                      </p>
                    </div>
                    <div className="col-12 mb-3">
                      <p className="Contact-details"><i class="fas fa-phone-alt phoneicon mr-3"></i>
                      <a href="tel:+919786250505">+91 9786250505</a></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-5">
                  <div className="form formcolour mt-5">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        {/* <label htmlFor="name">Name*</label> */}
                        <input type="text" value={this.state.name} className="form-control" placeholder="Your Name" name="name" id="name" onChange={this.changeHandle} aria-describedby="nameHelp" required />
                      </div>
                      <div className="form-group">
                        {/* <label classname = "form-text" htmlFor="email">Email Address*</label> */}
                        <input type="email" value={this.state.email} className="form-control" name="email" id="email" placeholder="E-mail address" onChange={this.changeHandle} aria-describedby="emailHelp" required />
                      </div>
                      <div className="form-group">
                        {/* <label classname = "form-text" htmlFor="contact">Phone*</label> */}
                        <input type="text" value={this.state.phone} className="form-control" name="phone" id="contact" placeholder="Phone Number" onChange={this.changeHandle} required />
                      </div>
                      <div className="form-group">
                        {/* <label classname = "form-text" htmlFor="message">Write Message</label> */}
                        <textarea value={this.state.message} className="form-control" name="message" id="message" placeholder="Your Message" rows="5" onChange={this.changeHandle}></textarea>
                      </div>
                      <button type="submit" className="form-button">Submit</button>
                      <div className="box">
                      </div>
                      <div id="popup1" className={this.state.class}>
                        <div className="popup">
                          <h2>Thank you</h2>
                          <p className="close" onClick={() => { this.setState({ class: "d-none overlay" }) }}>&times;</p>
                          <div className="content">
                            Thanks for your intrest we will get in touch with you
	                  	</div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <NavbarBottom />
          </div>
          :
          <Loading />
        }
      </div>
    )
  }
}
export default Contact;

// {/* <div className="App">
// <div style={{ width: 800, height: 450 }}>
//   <ImageSlider
//     image1={photo1}
//     image2={photo2}
//     sliderWidth={3}
//     sliderColor="green"
//     handleColor="red"
//     handleBackgroundColor="white"
//   />
// </div>
// </div>

// <div className="container">
// <Slider {...settings}>
// <div>
//   <img src = {photo1}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// <div>
//   <img src = {photo2}/>
// </div>
// </Slider>
// </div>


//     const settings = {
//       infinite: true,
//       speed: 300,
//       dots: true,
//       slidesToShow: 1,
//       slidesToScroll: 1
//     };
  
//   */}