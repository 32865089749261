import React from 'react';
import './AboutUs.css';
import Navbar from '../../components/Navbar/Navbar';
import NavbarBottom from '../../components/NavbarBottom/NavbarBottom';
import Loading from '../../components/Loading/Loading';

class AboutUs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 1800)
  }

  render() {
    return (
      <div>
        {this.state.loading === false ?
          <div>
            <Navbar title="About" />
            <div className="container about" >
              <div className="row">
                <div className="mt-5 col-6" >
                  <p className="about-content"><strong>EVOKE</strong>  is a budding architecture and interior firm in Chennai, India. Formed by a collective of architects, interior designers and engineers under the lead of Ar. Shibe Chakravarthy, a graduate from Coventry University, UK.</p>
                </div>
                <div className="col-6"></div>
                <div className="my-3 my-lg-5 col-6" >
                  <p className="about-content">Our approach is highly collaborative, Working from a network of creative studios across the globe, we are able to apply international expertise at a local level. We endeavor to produce exemplary designs working along with client’s requirements and input. Our firm currently works on residential, commercial and interiors across South India.</p>
                </div>
                <div className="col-6"></div>
              </div>
            </div>
            <NavbarBottom />
          </div>
          :
          <Loading />
        }
      </div>
    )
  }
}

export default AboutUs;