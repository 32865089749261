import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import NavbarBottom from '../../../components/NavbarBottom/NavbarBottom';
import ProjectView from '../../../components/ProjectView/ProjectView';
import photo1 from '../../../assets/projects/project2/1.jpg';
import photo2 from '../../../assets/projects/project2/2.jpg';
import photo3 from '../../../assets/projects/project2/3.jpg';
import photo4 from '../../../assets/projects/project2/4.jpg';
import photo5 from '../../../assets/projects/project2/5.jpg';
import photo6 from '../../../assets/projects/project2/6.jpg';
import photo7 from '../../../assets/projects/project2/7.jpg';
import photo8 from '../../../assets/projects/project2/8.jpg';
import photo9 from '../../../assets/projects/project2/9.jpg';
import photo10 from '../../../assets/projects/project2/10.jpg';
import photo11 from '../../../assets/projects/project2/11.jpg';
import photo12 from '../../../assets/projects/project2/12.jpg';
import photo13 from '../../../assets/projects/project2/13.jpg';


class Project2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      photos: [
        { id: 1, data: photo1 },
        { id: 2, data: photo2 },
        { id: 3, data: photo3 },
        { id: 4, data: photo4 },
        { id: 5, data: photo5 },
        { id: 6, data: photo6 },
        { id: 7, data: photo7 },
        { id: 8, data: photo8 },
        { id: 9, data: photo9 },
        { id: 10, data: photo10 },
        { id: 11, data: photo11 },
        { id: 12, data: photo12 },
        { id: 13, data: photo13 },
      ]
    }
  }

  render() {
    const { photos} = this.state;
    return (
      <div>
        <Navbar title="Koushik Residence, Salem" />
        <ProjectView photos={photos} />
        <NavbarBottom />
      </div>
    )
  }
}

export default Project2;