import React from 'react';
import './Projects.css';
import Navbar from '../../components/Navbar/Navbar';
import NavbarBottom from '../../components/NavbarBottom/NavbarBottom';
import Loading from '../../components/Loading/Loading';
import project1 from '../../assets/projects/project1/1.jpg';
import project2 from '../../assets/projects/project2/1.jpg';
import project3 from '../../assets/projects/project3/1.jpg';
import project4 from '../../assets/projects/project4/1.jpg';
import project5 from '../../assets/projects/project5/1.jpg';
import project6 from '../../assets/projects/project6/1.jpg';
import project7 from '../../assets/projects/project7/1.jpg';
import project8 from '../../assets/projects/project8/1.jpg';
import project9 from '../../assets/projects/project9/1.jpg';
import project10 from '../../assets/projects/project10/1.jpg';
import project11 from '../../assets/projects/project11/1.jpg';
import project12 from '../../assets/projects/project12/1.jpg';
import project13 from '../../assets/projects/project13/1.jpg';
import project14 from '../../assets/projects/project14/1.jpg';
import project15 from '../../assets/projects/project15/1.jpg';
import project16 from '../../assets/projects/project16/1.jpg';
import project17 from '../../assets/projects/project17/1.jpg';
import project18 from '../../assets/projects/project18/1.jpg';  
import project19 from '../../assets/projects/project19/1.jpg';
import project20 from '../../assets/projects/project20/1.jpg';
import project21 from '../../assets/projects/project21/1.jpg';
import project22 from '../../assets/projects/project22/1.jpg';
import project23 from '../../assets/projects/project23/1.jpg';

class Projects extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      projects: [
        { id: 1, photo: project6, title: "TrainStation, Scotland", link: "/train-station-scotland" },
        { id: 2, photo: project5, title: "The Palm Cafe, Bahrain", link: "/palm-cafe-bahrain" },
        { id: 3, photo: project4, title: "The Madras Museum, Chennai", link: "/madras-museum-chennai" },
        { id: 4, photo: project3, title: "Steak Sphere, UK", link: "/steak-sphere-uk" },
        { id: 5, photo: project2, title: "Koushik Residence, Salem", link: "/koushik-residence-salem" },
        { id: 6, photo: project1, title: "Arun Residence, Coimbatore", link: "/arun-residence-coimbatore" },
        { id: 7, photo: project7, title: "Interiors for Mr. Ravi Chandran,Chennai", link: "/interiors-for-ravi-chandran" },
        { id: 8, photo: project8, title: "Guru's Resisdence, Namakkal", link: "/guru-residence" },
        { id: 9, photo: project9, title: "Vinodh Residence, Salem", link: "/vinodh-residence" },
        { id: 10, photo: project10, title: "Vikash Residence, Salem", link: "/vikash-residence" },
        { id: 11, photo: project11, title: "Sachin Residence, Salem", link: "/sachin-residence" },
        { id: 12, photo: project12, title: "MKB Nagar Factory, Chennai", link: "/mkb-nagar-factory" },
        { id: 13, photo: project13, title: "Dharan Residence, Salem", link: "/dharan-residence" },
        { id: 14, photo: project14, title: "Mrs.Vithi, Chennai", link: "/vithi-chennai" },
        { id: 15, photo: project15, title: "Mr.Dhivyan, Chennai", link: "/dhivyan-chennai" },
        { id: 16, photo: project16, title: "Long Live Pizza, Salem", link: "/longlive-salem" },
        { id: 17, photo: project17, title: "Residence Kallakurichi", link: "/residence-kallakuruchi" },
        { id: 18, photo: project18, title: "Ozone, Salem", link: "/ozone-salem" },
        { id: 19, photo: project19, title: "Roof Top, Dubai Frame", link: "/rooftop-dubai" },
        { id: 20, photo: project20, title: "Suresh, Namakiripettai", link: "/suresh-namakiripettai" },
        { id: 21, photo: project21, title: "IOCL, Sankagiri", link: "/iocl-Sankagiri" },
        { id: 22, photo: project22, title: "Phifer, Chennai", link: "/Phifer-chennai" },
        { id: 23, photo: project23, title: "California Pets, Bahrain", link: "/Californiapets-Bahrain" },
      ]
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 1800)
  }

  render() {
    return (
      <div>
        {this.state.loading === false ?
          <div>
            <Navbar title="Projects" />
            <div className="container project" >
              <div className="row" >
                {this.state.projects.reverse().map(({ id, photo, title, link }) => (
                  <div className="content col-md-6 col-lg-4 text-center mb-5" >
                    <a href={"/projects"+link} >
                      <img className="project_img" src={photo} alt="project" />
                      <div className="project_overlay" >
                        <div className="project_detail fadeIn-top" >
                          <p className="project_text">{title}</p>
                        </div>
                      </div>
                      <p className="d-md-none project_mobile mt-2">{title}</p>
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <NavbarBottom />
          </div>
          :
          <Loading />
        }
      </div>
    )
  }
}

export default Projects;