import React from 'react';
import Fade from 'react-reveal/Fade';
import Navbar from '../../components/Navbar/Navbar';
import NavbarBottom from '../../components/NavbarBottom/NavbarBottom';
import Loading from '../../components/Loading/Loading';
import './Service.css';
import SitePlanning from '../../assets/site planning.svg';
import Visualization from '../../assets/3d.svg'
import video from '../../assets/video.svg'
import projectmanag from '../../assets/worker.svg'
import InteriorDesign from '../../assets/plan.svg';
class Services extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 1800)
  }

  render() {
    return (
      <div>
        {this.state.loading === false ?
          <div>
            <Navbar title="Services" />
            <Fade left>
            <div className="container">
              <div className="row mt-5">
                <div className="col-12 col-md-4">
                  <div class="card">
                    <i className="fas fa-building mt-3" style={{ textAlign: "center" }}></i>
                    <div className="card-body">
                      <h5 className="card-title" style={{ textAlign: "center" }}>Architecture</h5>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div class="card">
                    <img className = "serviceimage mt-3" src = {InteriorDesign} alt = "interior design"/>
                    <div className="card-body">
                      <h5 className="card-title" style={{ textAlign: "center" }}>Interior Design</h5>
                      
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div class="card">
                    <img className = "serviceimage mt-3" src = {SitePlanning} alt = "site planning"/>
                    <div className="card-body">
                      <h5 className="card-title" style={{ textAlign: "center" }}>Site planning</h5>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12 col-md-4">
                  <div class="card">
                    <img className = "serviceimage mt-3" src = {Visualization} alt = "3d"/>
                    <div className="card-body">
                      <h5 className="card-title" style={{ textAlign: "center" }}>3D Visuvalization</h5>
                      
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div class="card">
                    <img className = "serviceimage mt-3" src = {video} alt = "interior design"/>
                    <div className="card-body">
                      <h5 className="card-title" style={{ textAlign: "center" }}>Video Walkthrough</h5>
                      
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div class="card">
                    <img className = "serviceimage mt-3 text-center" src = {projectmanag} alt = "site planning" style = {{display: "block",marginLeft: "auto", marginRight: "auto" ,width: "40%"}}/>
                    <div className="card-body">
                      <h5 className="card-title" style={{ textAlign: "center" }}>Project Management</h5>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Fade>
            <NavbarBottom />
          </div>
          :
          <Loading />
        }
      </div>
    )
  }
}
export default Services;

// <div className="container Service">
// <p className="Service-about">We offer Architecture, Interior Design, Site Planning, Structural, Plumbing, Sanitation, Electrical,  3d Visualization, Video Walkthrough, Project Management, Etc.</p>
// </div>