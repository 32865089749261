import React from 'react';
import './Home.css';
import Navbar from '../../components/Navbar/Navbar';
import Loading from '../../components/Loading/Loading';
import photo1 from '../../assets/home/01.jpg';
import photo2 from '../../assets/home/02.jpg';
import photo3 from '../../assets/home/03.jpg';
import photo4 from '../../assets/home/04.jpg';
import photo5 from '../../assets/home/05.jpg';
import photo6 from '../../assets/home/06.jpg';
import photo7 from '../../assets/home/07.jpg';
import photo8 from '../../assets/home/08.jpg';
import photo9 from '../../assets/home/09.jpg';
import photo10 from '../../assets/home/10.jpg';
import photo11 from '../../assets/home/11.jpg';
import photo12 from '../../assets/home/12.jpg';
import arrow from '../../assets/homearrow.svg';


class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      photos: [
        { id: 1, data: photo1, text: "ABC Construction" },
        { id: 2, data: photo2, text: "ABC Construction" },
        { id: 3, data: photo3, text: "DEF Construction" },
        { id: 4, data: photo4, text: "GHI Construction" },
        { id: 5, data: photo5, text: "JKL Construction" },
        { id: 6, data: photo6, text: "MNO Construction" },
        { id: 7, data: photo7, text: "PQR Construction" },
        { id: 8, data: photo8, text: "STU Construction" },
        { id: 9, data: photo9, text: "VWX Construction" },
        { id: 10, data: photo10, text: "YZA Construction" },
        { id: 11, data: photo11, text: "BCD Construction" },
        { id: 12, data: photo12, text: "BCD Construction" }
      ],
      view: 1,
      loading: true
    }
  }

  componentDidMount() {
    setInterval(() => {
      this.state.view + 1 > this.state.photos.length ?
        this.setState({ view: 1 }) :
        this.setState({ view: this.state.view + 1 });
    }, 7000);

    setTimeout(() => {
      this.setState({ loading: false })
    }, 1800)
  }

  render() {

    console.log(this.state.view);

    const { photos, view, loading } = this.state;

    return (
      <div>
        {loading === false ?
          <div>
            <Navbar />
            <div className="home" >
              {photos.map(({ id, data, text }) => (
                id === view ?
                  <div key={id} className="fade_in shadow">
                    <img className="home_img" src={data} alt="slide" />
                  </div>
                  : <></>
              ))}

              <div className="btns d-none d-md-inline-block" >
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 1 })}></button>
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 2 })}></button>
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 3 })}></button>
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 4 })}></button>
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 5 })}></button>
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 6 })}></button>
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 7 })}></button>
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 8 })}></button>
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 9 })}></button>
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 10 })}></button>
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 11 })}></button>
                <button className="slide_btn" type="button" onClick={() => this.setState({ view: 12 })}></button>
              </div>

              <button className="back_btn" type="button" onClick={() => {
                view - 1 < 1 ?
                  this.setState({ view: photos.length }) :
                  this.setState({ view: view - 1 });
              }} >
                <img className="larrow" src={arrow} alt="larrow" />
                {/* <i className="fas fa-angle-left d-none d-md-inline-block"></i> */}
              </button>
              <button className="next_btn" type="button" onClick={() => {
                view + 1 > photos.length ?
                  this.setState({ view: 1 }) :
                  this.setState({ view: view + 1 });
              }} >
                <img className="rarrow" src={arrow} alt="rarrow"/>
                {/* <i className="fas fa-angle-right d-none d-md-inline-block"></i> */}
              </button>
            </div>
          </div>
          :
          <Loading />
        }
      </div>
    );
  }
}

export default Home;
