import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect, Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import './index.css';
import Home from './containers/Home/Home';
import AboutUs from './containers/AboutUs/AboutUs';
import Projects from './containers/Projects/Projects';
import Project1 from './containers/Projects/Project-Page/Project1';
import Project2 from './containers/Projects/Project-Page/Project2';
import Project3 from './containers/Projects/Project-Page/Project3';
import Project4 from './containers/Projects/Project-Page/Project4';
import Project5 from './containers/Projects/Project-Page/Project5';
import Project6 from './containers/Projects/Project-Page/Project6';
import Project7 from './containers/Projects/Project-Page/Project7';
import Project8 from './containers/Projects/Project-Page/Project8';
import Project9 from './containers/Projects/Project-Page/Project9';
import Project10 from './containers/Projects/Project-Page/Project10';
import Project11 from './containers/Projects/Project-Page/Project11';
import Project12 from './containers/Projects/Project-Page/Project12';
import Project13 from './containers/Projects/Project-Page/Project13';
import Project14 from './containers/Projects/Project-Page/Project14';
import Project15 from './containers/Projects/Project-Page/Project15';
import Project16 from './containers/Projects/Project-Page/Project16';
import Project17 from './containers/Projects/Project-Page/Project17';
import Project18 from './containers/Projects/Project-Page/Project18';
import Project19 from './containers/Projects/Project-Page/Project19';
import Project20 from './containers/Projects/Project-Page/Project20';
import Project21 from './containers/Projects/Project-Page/Project21';
import Project22 from './containers/Projects/Project-Page/Project22';
import Project23 from './containers/Projects/Project-Page/Project23';
import Contact from './containers/Contact/Contact';
import Services from './containers/Service/Service';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path='/aboutus' component={AboutUs} />
        <Route exact path='/projects' component={Projects} />
        <Route path='/projects/arun-residence-coimbatore' component={Project1} />
        <Route path='/projects/koushik-residence-salem' component={Project2} />
        <Route path='/projects/steak-sphere-uk' component={Project3} />
        <Route path='/projects/madras-museum-chennai' component={Project4} />
        <Route path='/projects/palm-cafe-bahrain' component={Project5} />
        <Route path='/projects/train-station-scotland' component={Project6} />
        <Route path='/projects/interiors-for-ravi-chandran' component={Project7} />
        <Route path='/projects/guru-residence' component={Project8} />
        <Route path='/projects/dharan-residence' component={Project9} />
        <Route path='/projects/mkb-nagar-factory' component={Project10} />
        <Route path='/projects/sachin-residence' component={Project11} />
        <Route path='/projects/vikash-residence' component={Project12} />
        <Route path='/projects/vinodh-residence' component={Project13} />
        <Route path='/projects/vithi-chennai' component={Project14} />
        <Route path='/projects/dhivyan-chennai' component={Project15} />
        <Route path='/projects/longlive-salem' component={Project16} />
        <Route path='/projects/residence-kallakuruchi' component={Project17} />
        <Route path='/projects/ozone-salem' component={Project18} />
        <Route path='/projects/rooftop-dubai' component={Project19} />
        <Route path='/projects/suresh-namakiripettai' component={Project20} />
        <Route path='/projects/iocl-Sankagiri' component={Project21} />
        <Route path='/projects/Phifer-chennai' component={Project22} />
        <Route path='/projects/Californiapets-Bahrain' component={Project23} />
        <Route path = '/contact' component = {Contact}/>
        <Route path = '/services' component = {Services}/>
        <Route
            path="*"
            render={() => 
              <Redirect to="/" />
            } />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
