import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import NavbarBottom from '../../../components/NavbarBottom/NavbarBottom';
import ProjectView from '../../../components/ProjectView/ProjectView';
import photo1 from '../../../assets/projects/project20/1.jpg';
import photo2 from '../../../assets/projects/project20/2.jpg';
import photo3 from '../../../assets/projects/project20/3.jpg';
import photo4 from '../../../assets/projects/project20/4.jpg';
import photo5 from '../../../assets/projects/project20/5.jpg';
import photo6 from '../../../assets/projects/project20/6.jpg';
import photo7 from '../../../assets/projects/project20/7.jpg';
import photo8 from '../../../assets/projects/project20/8.jpg';
import photo9 from '../../../assets/projects/project20/9.jpg';
import photo10 from '../../../assets/projects/project20/10.jpg';
import photo11 from '../../../assets/projects/project20/11.jpg';
import photo12 from '../../../assets/projects/project20/12.jpg';
import photo13 from '../../../assets/projects/project20/13.jpg';
import photo14 from '../../../assets/projects/project20/14.jpg';
import photo15 from '../../../assets/projects/project20/15.jpg';
import photo16 from '../../../assets/projects/project20/16.jpg';
import photo17 from '../../../assets/projects/project20/17.jpg';
import photo18 from '../../../assets/projects/project20/18.jpg';
import photo19 from '../../../assets/projects/project20/19.jpg';
import photo20 from '../../../assets/projects/project20/20.jpg';
import photo21 from '../../../assets/projects/project20/21.jpg';
import photo22 from '../../../assets/projects/project20/22.jpg';
import photo23 from '../../../assets/projects/project20/23.jpg';
import photo24 from '../../../assets/projects/project20/24.jpg';

class Project20 extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        photos: [
          { id: 1, data: photo1 },
          { id: 2, data: photo2 },
          { id: 3, data: photo3 },
          { id: 4, data: photo4 },
          { id: 5, data: photo5 },
          { id: 6, data: photo6 },
          { id: 7, data: photo7 },
          { id: 8, data: photo8 },
          { id: 9, data: photo9 },
          { id: 10, data: photo10 },
          { id: 11, data: photo11 },
          { id: 12, data: photo12 },
          { id: 13, data: photo13 },
          { id: 14, data: photo14 },
          { id: 15, data: photo15 },
          { id: 16, data: photo16 },
          { id: 17, data: photo17 },
          { id: 18, data: photo18 },
          { id: 19, data: photo19 },
          { id: 20, data: photo20 },
          { id: 21, data: photo21 },
          { id: 22, data: photo22 },
          { id: 23, data: photo23 },
          { id: 24, data: photo24 },
          { id: 25, data: photo15 },
          { id: 26, data: photo16 },
          { id: 27, data: photo17 },
          { id: 28, data: photo18 },
          { id: 29, data: photo19 },
          { id: 30, data: photo20 },
          { id: 31, data: photo21 },
          { id: 32, data: photo22 },
          { id: 33, data: photo23 },
          { id: 34, data: photo24 },
        ]
      }
    }
  
    render() {
      const { photos} = this.state;
      return (
        <div>
          <Navbar title="Suresh, Namakiripettai" />
              <ProjectView photos={photos}/>
          <NavbarBottom />
        </div>
      )
    }
  }
  
  export default Project20;