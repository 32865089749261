import react from 'react'
import './Loading.css';
import { RemoveScroll } from 'react-remove-scroll';

class Loading extends react.Component {
  render() {
    return (
      <RemoveScroll>
        <div className="linetag">
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
          <div className="line4"></div>
        </div>
      </RemoveScroll>
    )
  }
}
export default Loading;