import React from 'react';
import './Navbar.css';
import { withRouter } from 'react-router-dom';
import Logo from '../../assets/logo/Logo trans.png'
class Navbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      background: "title title-home position",
      navbarcolor : "white",
      titleshow : "d-block"
    }
    this.titletoggler = this.titletoggler.bind(this);
  }

  componentDidMount() {
    if (this.props.location.pathname !== '/') {
      this.setState({ background: "title" });
      this.setState({navbarcolor : "transparent"})
      var canvas = window.document.getElementById("city-top");
      var ctx = canvas.getContext("2d");
      var FPS = 1000;
      var TRAIL_PLAN = ["u", "r", "d", "b", "r", "c"];
      var pointCopy = function (src, dst) {
        dst.x = src.x;
        dst.y = src.y;
        dst.z = src.z;
        return dst;
      };
      var Trail = function (pos, t, plan_i) {
        this.pos = { x: 0, y: 0, z: 0 };
        this.start = { x: 0, y: 0, z: 0 };
        this.goal = { x: 0, y: 0, z: 0 };
        // this.start_time;
        // this.take_time;
        this.vertexes = [];
        pointCopy(pos, this.pos);
        pointCopy(pos, this.start);
        pointCopy(pos, this.goal);
        this.plan_i = plan_i % TRAIL_PLAN.length || 0;
        this.sz = pos.z;
        this.setNextGoal(t);
      };
      Trail.prototype.setNextGoal = function (t) {
        pointCopy(this.goal, this.start);
        this.plan_i = (this.plan_i + 1) % TRAIL_PLAN.length;
        switch (TRAIL_PLAN[this.plan_i]) {
          case "r":
            this.goal.x += Math.random() * 50 + 50;
            break;
          case "u":
            this.goal.y -= Math.random() * 250 + 100;
            break;
          case "d":
            this.goal.y = 0;
            break;
          case "b":
            this.goal.z += Math.random() * 1;
            break;
          case "c":
            this.goal.z = this.sz;
            break;
          default:
            break;
        }
        this.start_time = t;
        this.take_time = 100 + Math.random() * 100;
        this.vertexes.push(pointCopy(this.start, { x: 0, y: 0, z: 0 }));
        if (this.vertexes.length > 100) {
          this.vertexes.splice(0, this.vertexes.length - 100);
        }
      };
      Trail.prototype.update = function (t) {
        quadIn(
          t - this.start_time,
          this.start,
          this.goal,
          this.take_time,
          this.pos
        );
        if (t - this.start_time > this.take_time) {
          this.setNextGoal(this.start_time + this.take_time);
          this.update(t);
        }
      };
      Trail.prototype.draw = function (ctx, camera) {
        var i;
        var ps = { x: 0, y: 0 };
        ctx.beginPath();
        if (perspective(this.vertexes[0], camera, ps)) {
          ctx.moveTo(ps.x, ps.y);
        }
        for (i = 1; i < this.vertexes.length; i++) {
          if (perspective(this.vertexes[i], camera, ps)) {
            ctx.strokeStyle = "rgba(28,44,59," + 2 / (this.vertexes[i].z - camera.z) + ")";
            ctx.lineTo(ps.x, ps.y);
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(ps.x, ps.y);
          }
        }
        if (perspective(this.pos, camera, ps)) {
          ctx.strokeStyle = "rgba(28,44,59," + 2 / (this.pos.z - camera.z) + ")";
          ctx.lineTo(ps.x, ps.y);
          ctx.stroke();
        }
      };
      var quadIn = function (t, b, c, d, dst) {
        t /= d;
        dst.x = (c.x - b.x) * t * t + b.x;
        dst.y = (c.y - b.y) * t * t + b.y;
        dst.z = (c.z - b.z) * t * t + b.z;
      };
      var perspective = function (point, camera, dst) {
        var dz = point.z - camera.z;
        if (dz > 0) {
          dst.x = (point.x - camera.x) / dz;
          dst.y = (point.y - camera.y) / dz;
          return true;
        }
        return false;
      };
      var updateScene = function (ctx) {
        var i;
        var time_now = new Date().getTime();
        var time_d = time_now - time_pre;
        for (i = 0; i < trails.length; i++) {
          trails[i].update(time_now);
        }
        camera.x += (trails[0].pos.x - camera.x - 50) * 0.0002 * time_d;
        camera.y += (trails[0].pos.y - camera.y - 300) * 0.00002 * time_d;
        time_pre = time_now;
      };
      var drawScene = function (ctx) {
        var i;
        ctx.clearRect(-canvas.width / 2, -canvas.height / 2, canvas.width, canvas.height);
        for (i = 0; i < trails.length; i++) {
          trails[i].draw(ctx, camera);
        }
      };
      var trails = [];
      var i;
      var time_now = new Date().getTime();
      var time_pre = time_now;
      for (i = 0; i < 8; i++) {
        trails.push(new Trail({ x: Math.random() * 50 - 25, y: Math.random() * 50 - 25, z: i }, time_now, i));
      }
      var camera = { x: 0, y: 0, z: -2 };
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight / 2.5;
      ctx.translate(canvas.width / 2, canvas.height / 2);
      setInterval(function () {
        updateScene();
        drawScene(ctx);
      }, 1000 / FPS);
    }
    else {
      this.setState({ background: "title title-home position" });
    }
  }

  titletoggler(event){
    console.log("entered")
    console.log(this.state)
    if(this.state.titleshow === "d-block"){
      this.setState({titleshow : "d-none"})
      console.log("1");
      event.preventDefault();
    }else{
      this.setState({titleshow : "d-block"})
      console.log("2");
      event.preventDefault();
    }
  }

  render() {
    let view;
    if(this.props.location.pathname !== '/'){
      view = <canvas id = "city-top" className = "city-top-canvas"/>
    }
    let about, projects, services, contact;
    projects = "nav-link mr-1";
    services = "nav-link mr-1";
    contact = "nav-link mr-1";
    about = "nav-link mr-1";

    if (this.props.title) {
      let title = this.props.title;
      switch (title) {
        case "About Us":
          projects = "nav-link mr-1";
          services = "nav-link mr-1";
          contact = "nav-link mr-1";
          about = "nav-link mr-1 text-dark";
          break;
        case "Projects":
          about = "nav-link mr-1";
          services = "nav-link mr-1";
          contact = "nav-link mr-1";
          projects = "nav-link mr-1 text-dark";
          break;
        case "Services":
          about = "nav-link mr-1";
          services = "nav-link mr-1 text-dark";
          contact = "nav-link mr-1";
          projects = "nav-link mr-1";
          break;
        case "Contact":
          about = "nav-link mr-1";
          services = "nav-link mr-1";
          contact = "nav-link mr-1 text-dark";
          projects = "nav-link mr-1";
          break;
        default:
          break;
      }
    }

    return (
      <div className={this.state.background}>
        {view}
        <div className="nav-top">
          <nav className="navbar navbar-expand-md navbar-light" style = {{backgroundColor : this.state.navbarcolor }}>
            <a className="navbar-brand" href="/">
              <img src = {Logo} height = "50px" width = "150px" alt = "Logo"/>
            </a>
            <button onClick = {this.titletoggler} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav ml-auto">
                <a className={about} href="/aboutus">About</a>
                <a className={projects} href="/projects">Projects</a>
                <a className={services} href="/services">Services</a>
                <a className={contact} href="/contact">Contact</a>
              </div>
            </div>
          </nav>
          {this.props.title ?
            <div style = {{marginTop : "8%"}} className = {this.state.titleshow}>
              {/* <hr className="line" /> */}
              <h1 className="navbar-title">{this.props.title}</h1>
            </div>
            : <></>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(Navbar);