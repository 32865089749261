import React from 'react';
import './NavbarBottom.css';

class NavbarBottom extends React.Component {

  componentDidMount() {
    var canvas = window.document.getElementById("city-bottom");
    var ctx = canvas.getContext("2d");
    var FPS = 1000;
    var TRAIL_PLAN = ["u", "r", "d", "b", "r", "c"];
    var pointCopy = function (src, dst) {
      dst.x = src.x;
      dst.y = src.y;
      dst.z = src.z;
      return dst;
    };
    var Trail = function (pos, t, plan_i) {
      this.pos = { x: 0, y: 0, z: 0 };
      this.start = { x: 0, y: 0, z: 0 };
      this.goal = { x: 0, y: 0, z: 0 };
      // this.start_time;
      // this.take_time;
      this.vertexes = [];
      pointCopy(pos, this.pos);
      pointCopy(pos, this.start);
      pointCopy(pos, this.goal);
      this.plan_i = plan_i % TRAIL_PLAN.length || 0;
      this.sz = pos.z;
      this.setNextGoal(t);
    };
    Trail.prototype.setNextGoal = function (t) {
      pointCopy(this.goal, this.start);
      this.plan_i = (this.plan_i + 1) % TRAIL_PLAN.length;
      switch (TRAIL_PLAN[this.plan_i]) {
        case "r":
          this.goal.x += Math.random() * 50 + 50;
          break;
        case "u":
          this.goal.y -= Math.random() * 250 + 100;
          break;
        case "d":
          this.goal.y = 0;
          break;
        case "b":
          this.goal.z += Math.random() * 1;
          break;
        case "c":
          this.goal.z = this.sz;
          break;
        default:
          break;
      }
      this.start_time = t;
      this.take_time = 100 + Math.random() * 100;
      this.vertexes.push(pointCopy(this.start, { x: 0, y: 0, z: 0 }));
      if (this.vertexes.length > 100) {
        this.vertexes.splice(0, this.vertexes.length - 100);
      }
    };
    Trail.prototype.update = function (t) {
      quadIn(
        t - this.start_time,
        this.start,
        this.goal,
        this.take_time,
        this.pos
      );
      if (t - this.start_time > this.take_time) {
        this.setNextGoal(this.start_time + this.take_time);
        this.update(t);
      }
    };
    Trail.prototype.draw = function (ctx, camera) {
      var i;
      var ps = { x: 0, y: 0 };
      ctx.beginPath();
      if (perspective(this.vertexes[0], camera, ps)) {
        ctx.moveTo(ps.x, ps.y);
      }
      for (i = 1; i < this.vertexes.length; i++) {
        if (perspective(this.vertexes[i], camera, ps)) {
          ctx.strokeStyle = "rgba(28,44,59," + 2 / (this.vertexes[i].z - camera.z) + ")";
          ctx.lineTo(ps.x, ps.y);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(ps.x, ps.y);
        }
      }
      if (perspective(this.pos, camera, ps)) {
        ctx.strokeStyle = "rgba(28,44,59," + 2 / (this.pos.z - camera.z) + ")";
        ctx.lineTo(ps.x, ps.y);
        ctx.stroke();
      }
    };
    var quadIn = function (t, b, c, d, dst) {
      t /= d;
      dst.x = (c.x - b.x) * t * t + b.x;
      dst.y = (c.y - b.y) * t * t + b.y;
      dst.z = (c.z - b.z) * t * t + b.z;
    };
    var perspective = function (point, camera, dst) {
      var dz = point.z - camera.z;
      if (dz > 0) {
        dst.x = (point.x - camera.x) / dz;
        dst.y = (point.y - camera.y) / dz;
        return true;
      }
      return false;
    };
    var updateScene = function (ctx) {
      var i;
      var time_now = new Date().getTime();
      var time_d = time_now - time_pre;
      for (i = 0; i < trails.length; i++) {
        trails[i].update(time_now);
      }
      camera.x += (trails[0].pos.x - camera.x - 50) * 0.0002 * time_d;
      camera.y += (trails[0].pos.y - camera.y - 300) * 0.00002 * time_d;
      time_pre = time_now;
    };
    var drawScene = function (ctx) {
      var i;
      ctx.clearRect(-canvas.width / 2, -canvas.height / 2, canvas.width, canvas.height);
      for (i = 0; i < trails.length; i++) {
        trails[i].draw(ctx, camera);
      }
    };
    var trails = [];
    var i;
    var time_now = new Date().getTime();
    var time_pre = time_now;
    for (i = 0; i < 8; i++) {
      trails.push(new Trail({ x: Math.random() * 50 - 25, y: Math.random() * 50 - 25, z: i }, time_now, i));
    }
    var camera = { x: 0, y: 0, z: -2 };
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight / 2.5;
    ctx.translate(canvas.width / 2, canvas.height / 2);
    setInterval(function () {
      updateScene();
      drawScene(ctx);
    }, 10000 / FPS);
  }

  render() {
    return (
      <div className="navbar-bottom">
        <div className="nav-bottom">
          <div className="row"  >
            <div >
            </div>
            <div className="bottom-content col-md-3" >
              <p className="bottom-title">Contact Us</p>
              <p className="bottom-text">Call : <a href="tel:+919786250505">+91 9786250505</a></p>
              <p className="bottom-text">Mail : <a href="mailto:info@evokearch.in">info@evokearch.in</a></p>
            </div>
            <div className="bottom-content col-md-4" >
              <p className="bottom-title">Visit Us</p>
              <p className="bottom-text">Our Location</p>
              <p className="bottom-text">AK-2, RBN Tower,  2nd Floor, 4th Avenue, Shanthi Colony, Anna Nagar, Chennai - 600040</p>
            </div>
            <div className="bottom-content col-md-3" >
              <p className="bottom-title">Follow Us</p>
              <p className="insta"><a href="https://www.instagram.com/evoke_architecture/" target="_blank" rel="noreferrer" ><i class="fab fa-instagram mr-2"></i>Instagram</a></p>
              <p className="facebook"><a href="https://www.facebook.com/evokearchitecture" target="_blank" rel="noreferrer" ><i class="fab fa-facebook-square mr-2"></i>Facebook</a></p>
              <p className="facebook"><a href="https://youtube.com/channel/UC8WlqCYnO2AhRU3rakoSLTQ" target="_blank" rel="noreferrer" ><i class="fab fa-youtube mr-2"></i>YouTube</a></p>
            </div>
          </div>
        </div>
        <canvas id="city-bottom"/>
      </div>
    )
  }
}

export default NavbarBottom;